import {atozContextUtil, isKrakenEnv, isKrakenRolloutPath} from "@amzn/atoz-kraken-utility";

export const KRAKEN_POPSTAR_FEATURES = isKrakenEnv() ? atozContextUtil?.enabledFeatures : JSON.parse(
    document.getElementById("atoz-my-hr-app-root")?.dataset?.popstarFeatures || "[]"
)

export const KRAKEN_EMPLOYEE_ID = isKrakenEnv() ? atozContextUtil?.employeeId : document
    .getElementById("atoz-my-hr-app-root")?.dataset?.employeeId;
export const KRAKEN_EXTERNAL_AUTH = isKrakenEnv() ? atozContextUtil?.isExternalAuth : document.getElementById("atoz-my-hr-app-root")?.dataset?.isExternalNetwork;
export const updatedURl = (url: string) => {
    const updatedPath = url.replace("myhr", "neverland-myhr");
    return isKrakenRolloutPath() ? updatedPath : url;
}