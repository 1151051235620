import {getEnabledFeatureGates} from "src/utils/featureUtils";
import {isKrakenRolloutPath} from "@amzn/atoz-kraken-utility";

export enum Stage {
  test = "test",
  alpha = "alpha",
  beta = "beta",
  prod = "prod",
}

export const HELPFUL_RESOURCES_BREADCRUMB_PAGE_NAME = "helpfulResources";
export const HELPFUL_RESOURCES_LITE_BREADCRUMB_PAGE_NAME = "helpfulResourcesLite";
export const HTTPS_URL_PREFIX = "https://";
export const NON_PROD_ASSET_ENDPOINT = "estech-myhr-assets.integ.amazon.com";
export const PROD_ASSET_ENDPOINT = "estech-myhr-assets.amazon.work";
export const NON_PROD_REQUESTING_DOMAIN = "integ.amazon.com";
export const PROD_REQUESTING_DOMAIN = "amazon.work";
export const KEY_PAIR_ID =  "K3N4PZYFCQ8L9J";
export const FPP_LE_ID_1 =  "FPPLE1";
export const FPP_LE_ID_2 =  "FPPLE2";
export const FPP_LE_ID_3 =  "FPPLE3";
export const FPP_LE_ID_4 =  "FPPLE4";
export const FPP_LE_ID_5 =  "FPPLE5";

export const ENGLISH_LOCALE = "en-US";
export const SPANISH_LOCALE = "es-ES";

export const MLE_FPP_VIDEO_FEATURE_GATE_NAME = "my_hr_mle_video_experience";
export const MLE_COMPLICATIONS_FEATURE_GATE = "my_hr_mle_complications_experience";
export const MLE_FPP_FAB_FEATURE_GATE = 'my_life_events_fpp_fab_enabled';
export const WILDCARD_ALL = "*";

export const FAB_CLIENT_ID = "AtoZ_MLE_FAB";

export const BASE_URL_MYHR_WORKFLOW_BETA = isKrakenRolloutPath() ? "https://essportal.integ.amazon.com/neverland-myhr?workflowId=" : "https://essportal.integ.amazon.com/myhr?workflowId="
export const BASE_URL_MYHR_WORKFLOW_PROD = "https://atoz.amazon.com/myhr?workflowId="
